<script>

    import Layout from '../layouts/main.vue'
    import PageHeader from '../../components/page-header.vue'
    import TrainTicketVue from './TrainTicket.vue'
    // import RailSearchVue from './RailSearch.vue'
    import TicketSearchVue from '../../components/rail/TicketSearch.vue'
    import RailUtils from '../../utils/RailUtils'
    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: 'RailTrains',
        components: {
            Layout,
            PageHeader,
            TicketSearchVue,
            TrainTicketVue
        },
        data () {
            return {
                title: "Train Results",
                items: [
                    {
                        text: "Rail",
                    },
                    {
                        text: "Train Results",
                        active: true,
                    }
                ],
                sortBySelected:{
                    sortByPrice: 0,
                    sortByDuration: 0
                },
                filterSelected: {
                    filterByDirect: 0,
                    departingStationSelected: [],
                    arrivingStationSelected: [],
                    providerSelected: [],
                },
                filterOptions: {
                    departingStationOptions: [
                        {text: 'Berlin', value: 'berlin'},
                        {text: 'Paris', value: 'paris'}
                    ],
                    arrivingStationOptions: [
                        {text: 'Paris', value: 'paris'},
                        {text: 'Berlin', value: 'berlin'}
                    ],
                    sortByOptions: [
                        {text: 'Duration', value: 'duration'},
                        {text: 'Departure Time', value: 'departureTime'},
                        {text: 'Price', value: 'price'}
                    ]
                },
                isOverLayShow: false,
                isOutbound: true,
                isCllapseBtnHidden: false,
                isExpandBtnHidden: true,
                filteredOutboundTickets: [],
                matchedOutboundOfInboundTickets: [],
                filteredInboundTickets: [],
            }
        },
        computed: {
            ...mapGetters('rail', [
                'trainTicketsRS',
                'outboundTrainTicketResults',
                'inboundTrainTicketResults',
                'isRoundTrip',
                'searchInputValues'
            ]),
            outboundDepartingStationOptions: {
                get(){
                    let options = []
                    if(this.isOutbound){
                        options = RailUtils.getDepartureStationFilterOptions(this.outboundTrainTicketResults)
                    }else{
                        options = RailUtils.getDepartureStationFilterOptions(this.inboundTrainTicketResults)
                    }

                    return options
                },
                set(){

                }
            },
            outboundArrivingStationOptions: {
                get(){
                    let options = []
                    if(this.isOutbound){
                        options = RailUtils.getArrivalStationFilterOptions(this.outboundTrainTicketResults)
                    }else{
                        options = RailUtils.getArrivalStationFilterOptions(this.inboundTrainTicketResults)
                    }
                    return options
                },
                set(){}
            },
            outboundProviderOptions: {
                    get() {
                    let options = []
                    if(this.isOutbound){
                        options = RailUtils.getProviderOptions(this.outboundTrainTicketResults)
                    }else{
                        options = RailUtils.getProviderOptions(this.inboundTrainTicketResults)
                    }
                    return options
                },
                set(){}
            }
        },
        watch: {
            trainTicketsRS(){
                //重新搜索成功，清空原来的数据
                this.resetDefaul()
                this.isOverLayShow = false
            },
            outboundTrainTicketResults(){

                this.filteredOutboundTickets = Object.assign([], this.outboundTrainTicketResults)
                this.filteredInboundTickets = Object.assign([], this.inboundTrainTicketResults)
            },
            inboundTrainTicketResults(){
                // console.info("Watch")
                this.filteredInboundTickets = this.inboundTrainTicketResults;
                // console.info(this.filteredInboundTickets)
            }
        },
        created() {

            //第一次进来
            let outboundTrainTicketResultsSession = sessionStorage.getItem('outboundTrainTicketResults') ? JSON.parse(sessionStorage.getItem('outboundTrainTicketResults')) : [];
            let inboundTrainTicketResultsSession = sessionStorage.getItem('inboundTrainTicketResults') ? JSON.parse(sessionStorage.getItem('inboundTrainTicketResults')) : []
            let isRoundTripSession = JSON.parse(sessionStorage.getItem('isRoundTrip'));
            let searchInputValuesSession = sessionStorage.getItem('searchInputValues') ? JSON.parse(sessionStorage.getItem('searchInputValues')) : null;
            //更新 vuex
            this.updateTrainTickets({
                outboundTrainTicketResults: outboundTrainTicketResultsSession, inboundTrainTicketResults: inboundTrainTicketResultsSession, isRoundTrip: isRoundTripSession,searchInputValues:searchInputValuesSession
            })

        },
        methods: {
            ...mapActions('rail', ['updateTrainTickets']),
            sortAndFilterTickets() {
                // this.isOverLayShow = true
                if(this.isOutbound){
                    this.filteredOutboundTickets = RailUtils.getSortAndFilteredTickets(this.outboundTrainTicketResults, this.sortBySelected, this.filterSelected)
                }else{
                    this.filteredInboundTickets = RailUtils.getSortAndFilteredTickets(this.matchedOutboundOfInboundTickets, this.sortBySelected, this.filterSelected)
                }
            },
            togglBtn () {
                this.isExpandBtnHidden = !this.isExpandBtnHidden
                this.isCllapseBtnHidden = !this.isCllapseBtnHidden
            },
            filterInboundTickets(selectedOutbound) {
                this.isOverLayShow = true
                this.isOutbound = false
                let copyOfInboundResults = JSON.parse(JSON.stringify( this.inboundTrainTicketResults));
                //选了outbound后，inbound需要后台match的选项才能选择
                this.matchedOutboundOfInboundTickets = RailUtils.getFilteredInboundTickets(copyOfInboundResults, selectedOutbound)
                // console.info("-----")
                // console.info(this.matchedOutboundOfInboundTickets)
                //更新 vuex
                this.updateTrainTickets({
                    outboundTrainTicketResults: this.outboundTrainTicketResults,
                    inboundTrainTicketResults: this.matchedOutboundOfInboundTickets,
                    isRoundTrip: this.isRoundTrip,searchInputValues: this.searchInputValues
                })
                this.isOverLayShow = false
            },
            reSearch(){
                this.isOverLayShow = true
                this.isOutbound = true
            },
            resetDefaul(){
                this.filteredOutboundTickets = []
                this.matchedOutboundOfInboundTickets = []
                this.filteredInboundTickets = []
                this.outboundDepartingStationOptions = []
                this.outboundArrivingStationOptions = []
                this.outboundProviderOptions = []
                this.sortBySelected.sortByPrice = 0
                this.sortBySelected.sortByDuration = 0
                this.filterSelected.filterByDirect = 0
            }
        }
    }

</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"></PageHeader>
        <!-- Two columns layout -->
        <b-card>
            <!-- <b-row>
                <b-col cols="10">
                    <span class="font-size-16">Modify your search</span>
                </b-col>
                <b-col>
                    <i v-b-toggle.collapse-1-inner
                    class="mdi mdi-arrow-up-drop-circle-outline font-size-20"
                    v-show="isCllapseBtnHidden"
                    @click="togglBtn"
                    ></i>
                    <i v-b-toggle.collapse-1-inner
                    class="mdi mdi-arrow-down-drop-circle-outline font-size-20"
                    v-show="isExpandBtnHidden"
                    @click="togglBtn"
                    ></i>
                </b-col>
            </b-row> -->
            <div class="d-flex justify-content-between border-bottom">
                <div>
                    <span class="font-size-16">Modify your search</span>
                </div>
                <div>
                    <i v-b-toggle.train-search-collapse-inner
                    class="mdi mdi-arrow-up-drop-circle-outline font-size-20"
                    v-show="isCllapseBtnHidden"
                    @click="togglBtn"
                    ></i>
                    <i v-b-toggle.train-search-collapse-inner
                    class="mdi mdi-arrow-down-drop-circle-outline font-size-20"
                    v-show="isExpandBtnHidden"
                    @click="togglBtn"
                    ></i>
                </div>
            </div>
            <b-collapse id="train-search-collapse-inner" class="mt-2">
                <TicketSearchVue @reSearch="reSearch" :is-in-train-list-page="true"></TicketSearchVue>
            </b-collapse>
        </b-card>
        

        <b-overlay :show="isOverLayShow" rounded="sm">
        <b-row>
            <!-- Filter -->
            <b-col cols="3">
                <b-card no-body>
                    <b-card-header 
                    header-class="font-weight-bold bg-soft-primary" 
                    header-text-variant="dark"
                    header="Filter by"
                    ></b-card-header>
                        <div class="p-3">
                        <b-form-group label="Stops"
                        >
                            <b-form-checkbox
                                v-model="filterSelected.filterByDirect"
                                value="1"
                                unchecked-value="0"
                                @change="sortAndFilterTickets"
                                >
                                Direct
                            </b-form-checkbox>
                        </b-form-group>

                        <b-form-group :label="isOutbound ? 'Outbound': 'Inbound'" >
                                <b-form-group label="Departing Station">
                                    <b-form-checkbox-group
                                    v-model="filterSelected.departingStationSelected"
                                    :options="outboundDepartingStationOptions"
                                    @change="sortAndFilterTickets"
                                    stacked
                                    ></b-form-checkbox-group>
                                </b-form-group>

                                <b-form-group label="Arriving Station">
                                    <b-form-checkbox-group
                                    v-model="filterSelected.arrivingStationSelected"
                                    :options="outboundArrivingStationOptions"
                                    @change="sortAndFilterTickets"
                                    stacked
                                    ></b-form-checkbox-group>
                                </b-form-group>
                        </b-form-group>

                        <b-form-group label="Provider"
                        >
                            <b-form-checkbox-group
                            v-model="filterSelected.providerSelected"
                            :options="outboundProviderOptions"
                            @change="sortAndFilterTickets"
                            stacked
                            ></b-form-checkbox-group>
                        </b-form-group>

                        <b-form-group label="Sort by">
                            <b-form-checkbox
                            v-model="sortBySelected.sortByPrice"
                            value="1"
                            unchecked-value="0"
                            @change="sortAndFilterTickets"
                            >
                                Price
                            </b-form-checkbox>
                            <b-form-checkbox
                                v-model="sortBySelected.sortByDuration"
                                value="1"
                                unchecked-value="0"
                                @change="sortAndFilterTickets"
                                >
                                Duration
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
                </b-card>
            </b-col>

            <!-- Results -->
            <KeepAlive>
            <b-col cols="9">
                
                <b-card>
					<p class=""><span class="text-danger">EUR 7.45</span> booking fee will be charged</p>
                    <h5>Please select your <b>{{isOutbound ? 'OUTBOUND' : 'INBOUND'}}</b> trip</h5>
                    <div class="d-flex justify-content-between">
                        <div class="d-flex text-center">
                            <div>
                                {{searchInputValues.fromPlaceName}}<br/>
                                {{searchInputValues.departureDate}}
                            </div>
                            <div class="mx-2">
                                <i v-if="isRoundTrip" class="mdi mdi-arrow-left-right-bold"></i>
                                <i v-else class="mdi mdi-arrow-right-bold"></i>
                            </div>
                            <div>
                                {{searchInputValues.toPlaceName}}<br/>
                                <span v-if="isRoundTrip">{{searchInputValues.returnDate}}</span>
                            </div>
                        </div>
                        <div>
                            <!-- <div class="border mr-5 p-2 rounded font-size-16 text-white bg-secondary" ><b>{{isRoundTrip ? 'ROUND TRIP' : ''}}</b></div> -->
                        </div>
                    </div>
                    
                </b-card>
                <template v-if="isOutbound">
                    <TrainTicketVue v-for="(ticketGroup, index) in filteredOutboundTickets"
                    :train-ticket="ticketGroup" :is-outbound="isOutbound" :key-prefix="'train-outbound-'+index" 
                    @toInboundOptions="filterInboundTickets"
                    :key="'outbound-train-'+index">
                    </TrainTicketVue>
                </template>
                <template v-if="!isOutbound && isRoundTrip">
                    <TrainTicketVue v-for="(ticketGroup, index) in filteredInboundTickets"
                    :train-ticket="ticketGroup" :is-outbound="isOutbound" :key-prefix="'train-inbound-'+index" :key="'inbound-train-'+index">
                    </TrainTicketVue>
                </template>
                
            </b-col>
            </KeepAlive>
        </b-row>
        </b-overlay>

    </Layout>
</template>



<style scoped>


</style>